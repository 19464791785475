import { Component, OnInit, OnDestroy } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Order } from '../../models/order';
import { Sales } from '../../models/sales';
import { Router } from '@angular/router';
import { Item } from '../../models/item';
import { PrintReceipt } from 'src/app/models/printreceipt';
import { OrderLite } from 'src/app/models/orderlite';

@Component({
    selector: 'app-annapurna',
    templateUrl: './annapurna.component.html',
    styleUrls: ['./order.component.css']
})

export class AnnapurnaComponent implements OnInit, OnDestroy {
    items: Item[];
    order: Order;
    errorMsg: string;
    cartObj: Sales[];
    totalCount = 0;
    header: string;
    oldCategoryId; number = 0;
    isOne = false;
    isTwo = false;
    isThree = false;
    isFour = false;
    isFive = false;
    isSix = false;
    isSeven = false;
    isEight = false;
    printRpt: PrintReceipt;
    orderLite: OrderLite;
    role: String;

    constructor(private router: Router, private itemSrv: ItemService) {
        console.log('AnnapurnaComponent: constructor ');
        this.role = sessionStorage.getItem('role');
    }

    ngOnInit() {
        console.log('AnnapurnaComponent: ngOnInit ');
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
        this.cartObj = null;
        this.pageRefresh();
        this.printRpt = new PrintReceipt();
        this.printRpt.printerOption = 'L';
        this.printRpt.printCount = 1;
        this.printRpt.printerName = 'EPSON TM-T88V Receipt';
    }
    ngOnDestroy() {
        console.log('AnnapurnaComponent: ngOnDestroy');
    }
    getActiveItems(): void {
        this.itemSrv.getActiveItems(false)
            .subscribe((resp: Item[]) => {
                this.items = resp;
                if (resp != null) {
                    for (const item of resp) {
                        if (item.categoryId === 1) {
                            this.isOne = true;
                        } else if (item.categoryId === 2) {
                            this.isTwo = true;
                        } else if (item.categoryId === 3) {
                            this.isThree = true;
                        } else if (item.categoryId === 4) {
                            this.isFour = true;
                        } else if (item.categoryId === 5) {
                            this.isFive = true;
                        } else if (item.categoryId === 6) {
                            this.isSix = true;
                        } else if (item.categoryId === 7) {
                            this.isSeven = true;
                        } else if (item.categoryId === 8) {
                            this.isEight = true;
                        }
                    }
                }
            },
                error => console.log('Error :: ' + error)
            );
    }
    selectCash() {
        let val = 0;
        this.order = new Order();
        this.order.printerOption = this.printRpt.printerOption;
        this.order.printerName = this.printRpt.printerName;
        this.order.salesType = 'K';
        this.order.applicationType = 'K';
        this.order.sales = [];
        let sales: Sales;
        this.cartObj.forEach(obj => {
            sales = new Sales();
            sales.itemId = obj.itemId;
            sales.quantity = obj.quantity;
            sales.price = obj.price;
            sales.itemName = obj.itemName;
            val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            sales.togo = obj.togo;
            this.order.sales.push(sales);
        });
        this.totalCount = val.valueOf();
        this.order.salesAmount = this.totalCount;
        this.itemSrv.saveOrder(this.order)
            .subscribe((resp: Order) => {
                if (this.printRpt.printerOption === 'L') {
                    this.orderLite = resp;
                    this.printReceipt(null);
                } else {
                    this.pageRefresh();
                }
                this.reset();
            },
                error => {
                    this.errorMsg = 'Invalid onSave';
                    console.log('Error :: ' + this.errorMsg);
                }
            );
    }
    addItems(itemId: number, itemName: string, price: number, togo: string): void {
        console.log('addItems: ' + itemId + ' : ' + itemName + ' : ' + price);
        if (this.cartObj != null) {
            let isAdded = false;
            this.cartObj.forEach(element => {
                if (element.itemId === itemId) {
                    element.quantity += 1;
                    isAdded = true;
                }
            });
            if (!isAdded) {
                const cart = new Sales();
                cart.quantity = 1;
                cart.itemId = itemId;
                cart.price = price;
                cart.itemName = itemName;
                cart.togo = togo;
                this.cartObj.push(cart);
            }
        } else {
            this.cartObj = [];
            const cart = new Sales();
            cart.quantity = 1;
            cart.itemId = itemId;
            cart.price = price;
            cart.itemName = itemName;
            cart.togo = togo;
            this.cartObj.push(cart);
        }
        let val = 0;
        this.cartObj.forEach(obj => {
            val = Math.round((val + (obj.price * obj.quantity).valueOf())*100)/100;
            console.log('total count: ' + this.totalCount + ' : ' + val);
        });
        this.totalCount = val;
        sessionStorage.setItem('cartObj', JSON.stringify(this.cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + '');
    }
    gotoPayment(){
        if (this.cartObj != null && this.cartObj.length > 0) {
             this.router.navigateByUrl('/sales/payment');
        }
    }
    printReceipt(modal): void {
        this.printRpt.id = this.orderLite.id;
        this.printRpt.createdDate = this.orderLite.createdDate;
        this.printRpt.salesAmount = this.order.salesAmount;
        this.printRpt.sales = this.order.sales;
        if (this.printRpt.printerOption === 'L') {
            this.printRpt.printerName = null;
        }
        this.itemSrv.printReceipt(this.printRpt).subscribe();
        if (modal != null) {
            modal.hide();
        }
        this.pageRefresh();
    }
    delete(itemId: number) {
        const cartObj = [];
        let val = 0;
        this.cartObj.forEach(obj => {
            if (obj.itemId !== itemId) {
                cartObj.push(obj);
                val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            }

        });
        this.cartObj = cartObj;
        this.totalCount = val.valueOf();
        sessionStorage.setItem('cartObj', JSON.stringify(cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + '');
    }
    negativeCount(itemId: number) {
        let val = 0;
        let isDelete = false;
        this.cartObj.forEach(obj => {
            if (obj.itemId === itemId) {
                if (obj.quantity === 1) {
                    isDelete = true;
                } else {
                    obj.quantity = obj.quantity - 1;
                    val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
                    console.log('total count: ' + this.totalCount + ' : ' + val);
                }
            } else {
                val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            }
        });
        if (isDelete) {
            this.delete(itemId);
        } else {
            this.totalCount = val.valueOf();
        }
        sessionStorage.setItem('cartObj', JSON.stringify(this.cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + '');
    }
    addItem(itemId: number): void {
        this.cartObj.forEach(element => {
            if (element.itemId === itemId) {
                element.quantity += 1;
            }
        });
        let val = 0;
        this.cartObj.forEach(obj => {
            val = Math.round((val + (obj.price * obj.quantity).valueOf()) * 100) / 100;
            console.log('total count: ' + this.totalCount + ' : ' + val);
        });
        this.totalCount = val;
        sessionStorage.setItem('cartObj', JSON.stringify(this.cartObj));
        sessionStorage.setItem('cartCount', this.totalCount + '');

    }
    reset(): void {
        this.cartObj = null;
        this.order = null;
        this.totalCount = null;
        this.errorMsg = null;
        this.oldCategoryId = null;
        sessionStorage.removeItem('cartCount');
        sessionStorage.removeItem('cartObj');
    }
    pageRefresh() {
        if (this.cartObj == null) {
            this.getActiveItems();
        }
    }

    checkStatus(categoryId: number): boolean {
        if (this.oldCategoryId === categoryId) {
            return false;
        }
        if (categoryId === 1) {
            this.header = 'Tiffin';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 2) {
            this.header = 'Dosa';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 3) {
            this.header = 'Rice';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 4) {
            this.header = 'Drinks';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 5) {
            this.header = 'Snacks';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 6) {
            this.header = 'Sweets';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 7) {
            this.header = 'Pickles';
            this.oldCategoryId = categoryId;
            return true;
        } else if (categoryId === 8) {
            this.header = 'Batter';
            this.oldCategoryId = categoryId;
            return true;
        }
        return false;
    }
}
