import { Component, OnInit } from '@angular/core';
import { Catelog } from '../../models/catelog';

@Component({
  selector: 'app-catelog',
  templateUrl: './catelog.component.html',
  styleUrls: ['./catelog.component.css']
})
export class CatelogComponent implements OnInit {

  catelog: Catelog[];
  error: string;
  constructor(){ 
    console.log('CatelogComponent: constructor: ' );
  }

  ngOnInit() {
  }

}
